.container {
  width: 100%;
  box-sizing: border-box !important;
  overflow: hidden;
}

.formContainer {
  display: flex;
}

.formText,
.formPart {
  height: 100%;
  width: 50%;
  box-sizing: border-box;
  padding: 40px 50px;
}

.formTextHeading {
  font-size: 40px;
  font-weight: 300;
}

.lineBreaker {
  width: 100px;
  height: 3px;
  background-color: #be3627;
}

.formTextContent {
  font-size: 20px;
  font-weight: 200;
  line-height: 30px;
}

.socialFooter {
  background-color: #ededed;
  height: 170px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
  overflow: hidden;
  padding: 10px 50px;
}

h4 {
  padding: 0;
  margin: 20px 0 5px 0;
  font-size: 22px;
}

.servicesSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
}

h6 {
  margin: 0;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 300;
}

.twoSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
  margin-left: 80px;
}

.contactInfo {
  margin: 45px 0;
  margin-left: 80px;
}

.contactInfo h4 {
  margin: 5px 10px;
  font-size: 18px;
  font-weight: 400;
}

.icon img {
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  overflow: hidden;
}

.breakerLine {
  background-color: #ededed;
  margin: 0;
  width: 100%;
  opacity: 0.6;
}

.copyright {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #ededed;
}

.rights {
  padding: 3px 30px;
}

.rights p {
  font-weight: 100;
  opacity: 0.8;
  margin: 10px;
}

.terms {
  text-align: right;
  padding: 5px 30px;
  margin-top: 13px;
}

.terms p {
  opacity: 0.6;
  margin: 2px;
}

@media only screen and (max-width: 1100px) {
  .formContainer {
    display: block;
  }

  .formText {
    width: 100%;
    text-align: center;
  }

  .formPart {
    width: 100%;
  }

  .lineBreaker {
    margin-left: 36%;
    width: 200px;
  }

  .socialFooter {
    height: 230px;
    padding: 0;
  }

  .oneSection {
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    padding-left: 30px;
  }

  .serviceType {
    padding-top: 15px;
  }

  .serviceSectionOne {
    line-height: 30px;
  }

  .twoSection {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0;
  }

  .address {
    box-sizing: border-box;
    width: 100%;
    padding-left: 17px;
  }

  .contactInfo {
    margin: 0;
    padding-top: 50px;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .formContainer {
    display: block;
  }

  .formText,
  .formPart {
    width: 100%;
    height: 50%;
    padding: 20px 30px;
  }

  .lineBreaker {
    margin-left: 34%;
    width: 100px;
  }

  .socialFooter {
    height: 530px;
    display: block;
    padding: 10px;
  }

  .oneSection {
    padding-left: 0;
  }

  .servicesSection {
    text-align: center;
    /* padding-left: -5px; */
  }

  .serviceSectionOne {
    line-height: 26px;
  }

  .twoSection {
    display: block;
    text-align: center;
    margin-left: 0;
  }

  .address {
    padding-bottom: 0;
  }

  .contactInfo {
    margin-left: 0;
    margin-bottom: 20px;
  }

  h4 {
    text-align: center;
  }

  .copyright {
    display: block;
    text-align: center;
  }

  .terms {
    text-align: center;
  }
}
