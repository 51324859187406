.container {
  /* border: 1px solid black; */
  margin: 40px;
  height: 250px;
  padding: 10px 30px;
  background-color: white;
  overflow: hidden;
  position: relative;
  border-radius: 30px;
  box-sizing: border-box;
  transition: all 0.3s linear;
  box-shadow: 8px 8px 30px 0 rgb(42 67 113 / 15%);
  text-align: center;
}

.container h1 {
  font-size: 30px;
  font-weight: bold;
}

.container p {
  font-weight: 100;
  line-height: 2em;
}

.container:hover {
  background-color: #55cff8;
  color: white;
  cursor: pointer;
  transform: translateY(-10px);
}

/* @media only screen and (max-width: 1100px) {} */

@media only screen and (max-width: 600px) {
  .container {
    height: 350px;
    padding: 10px 20px;
  }

  
}