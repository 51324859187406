.container {
  height: 350px;
  width: 100%;
  box-sizing: border-box;
}

.content {
  text-align: center;
}

.container h1 {
  font-size: 32px;
  font-weight: 600;
  padding-top: 60px;
}

.container hr {
  border: 1px solid #be3627;
  width: 100px;
  margin-bottom: 10px;
}

.slider-container {
  height: 300px;
  width: 100%;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slides {
  height: 300px;
  width: 100%;
  position: relative;
}

.slide-image {
  width: 90%;
  height: 100%;
  position: absolute;
  object-fit: fill;
  padding-left: 5%;
}

.all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 85%;
  justify-content: center;
  z-index: 200;
}

.dot {
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  margin: 0px 3px;
  background-color: transparent;
  /* background-color: rgba(0, 0, 0, 0.3); */
  border-radius: 50%;
  display: inline-block;
}

.slider-container:hover .dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.slider-container:hover .dot {
  background-color: rgba(0, 0, 0, 0.3);
}
.slider-container:hover .active-dot {
  background-color: rgba(255, 255, 255, 0.5);
}

.play-pause {
  color: black;
}
