.serviceContainer {
  background-image: url(../../Images/Nextad-Web-BG3.png);
  width: 100%;
  box-sizing: border-box;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.serContain {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.serviceTitle {
  text-align: center;
  padding-top: 30px;
  font-size: 40px;
}

@media screen and (max-width: 1100px) {
  .serviceContainer {
    background-image: url(../../Images/Nextad-Web-Photgraphy-Ipad-01.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: scroll;
  }

  .serContain {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .serviceTitle {
    font-weight: bold;
  }
}

@media screen and (max-width: 600px) {
  .serviceContainer {
    background-image: url("../../Images/Nextad-Photography-Phone-1.png");
    box-sizing: border-box;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: fixed;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .serContain {
    display: block;
    padding-bottom: 10px;
  }

  .serviceTitle {
    font-size: 35px;
    color: black;
  }
}
