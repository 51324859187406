.container {
  padding: 40px 100px;
  text-align: center;
  box-sizing: border-box;
}

.container h1 {
  font-size: 32px;
  font-weight: 600;
}

.container hr {
  border: 1px solid #be3627;
  width: 100px;
  margin-bottom: 50px;
  background-color: white;
}

.container p {
  font-weight: 100;
  font-size: 18px;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 12px;
  }

  .container h1 {
    font-size: 36px;
  }

  .container p {
    font-size: 22px;
  }
}