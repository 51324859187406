.container {
  width: 80%;
  height: 250px;
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 40px;
  margin-left: 10%;
  margin-bottom: 50px;
  display: flex;
}

.taskImage {
  width: 25%;
  height: 100%;
  padding-top: 2%; 
}

.taskImage img {
  width: 80%;
  height: 80%;
  box-sizing: border-box;
  object-fit: fill;
}

.oneContainer {
  display: flex;
  height: 100%;
  width: 75%;
  box-sizing: border-box;
  overflow: hidden;
}

.role {
  height: 100%;
  width: 40%;
  font-size: 20px;
  padding: 10px 30px;
  box-sizing: border-box;
}

.location {
  height: 100%;
  width: 30%;
  font-size: 20px;
  padding: 20px 80px;
  box-sizing: border-box;
}

.duration {
  height: 100%;
  width: 30%;
  font-size: 20px;
  padding: 10px 40px;
  box-sizing: border-box;
  line-height: 35px;
}

.duration button {
  width: 100px;
  padding: 10px;
  cursor: pointer;
  border-radius: 25px;
  font-size: 15px;
  box-sizing: border-box;
  margin-top: 25px;
  color: black;
}

.duration a {
  text-decoration: none;
}

.container:hover {
  box-shadow: 8px 8px 30px 0 rgb(42 67 113 / 15%);
}

@media only screen and (max-width: 1200px) {

  .duration {
    font-size: 18px;
    padding: 15px 20px;
  }

  .role {
    width: 30%;
    box-sizing: border-box;
  }

  .location {
    width: 40%;
  }
}

@media only screen and (max-width: 1100px) {
  .taskImage {
    width: 30%;
    padding-top: 3%;
    height: 100%;
  }

  .taskImage img{
    width: 100%;
  }

  .oneContainer {
    width: 70%;
  }

  .role {
    width: 30%;
    font-size: 18px;
    padding: 10px 0;
    box-sizing: border-box;
    word-wrap: break-word;
  }

  .location {
    width: 40%;
    font-size: 18px;
    padding: 20px 45px;
  }

  .duration {
    width: 30%;
    font-size: 18px;
    padding: 15px 5px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    height: 650px;
    box-sizing: border-box;
    overflow: hidden;
    display: block;
  }

  .taskImage{
    width: 100%;
    height: 40%;
  }

  .taskImage img{
    width: 100%;
    height: 100%;
  }

  .oneContainer {
    width: 100%;
    height: 60%;
    display: block;
    text-align: center;
  }

  .role{
    width: 100%;
    height: 15%;
    padding: 5px 10px;
  }

  .location {
    width: 100%;
    height: 25%;
    margin-top: 20px;
  }

  .duration {
    width: 100%;
    height: 50%;
  }

  .duration button {
    margin-top: 14px;
  }
}