.container {
  background-image: url(../../Images/Nextad-Web-BG2.png);
  width: 100%;
  height: 400px;
  box-sizing: border-box;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
}

.header {
  text-align: center;
  padding-top: 40px;
}

.header h1 {
  font-size: 32px;
  font-weight: 600;
}

.header hr {
  border: 1px solid #be3627;
  width: 100px;
  margin-bottom: 10px;
}

.cardContainer {
  display: flex;
  box-sizing: border-box;
  text-align: center;
}

.card {
  width: 25%;
  padding-top: 20px;
}

.card h1 {
  font-size: 55px;
  margin-bottom: 10px;
}

.card h5 {
  font-size: 15px;
  margin-top: 0;
}

@media only screen and (max-width: 1100px) {
  .container {
    background-attachment: scroll;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    background-image: url(../../Images/Nextad-Photography-Phone-2.png);
    height: 520px;
    background-attachment: scroll;
  }

  .cardContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 30px;
  }

  .card {
    text-align: center;
  }

  .header h1{
    font-weight: bold;
  }

  .card h1{
    font-size: 50px;
  }

  .card h5 {
    font-size: 15px;
  }
}
