.slideImage {
  height: 650px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.slideImage img {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  object-fit: fill;
}

.introContainer {
  padding: 40px 100px;
  text-align: center;
  box-sizing: border-box;
}

.introContainer h1 {
  font-size: 32px;
  font-weight: 600;
}

.introContainer hr {
  border: 1px solid #be3627;
  width: 100px;
  margin-bottom: 50px;
}

.introContainer p {
    font-weight: 100;
    font-size: 18px;
}

@media only screen and (max-width: 1100px) {
  .slideImage {
    height: 400px;    
  }

  .introContainer {
    padding: 40px 50px;
  }

  .introContainer h1 {
    font-size: 35px;
    margin: 0;
    padding-bottom: 20px;;
  }
}

@media only screen and (max-width: 600px) {
  .slideImage {
    height: 200px;
  }

  .introContainer {
    padding: 30px;
  }
}