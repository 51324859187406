.rightContainer,
.leftContainer {
  width: 100%;
  height: 450px;
  box-sizing: border-box;
  display: flex;
}

.breakLineRight,
.breakLineLeft {
  border: 1px solid #be3627;
  width: 100px;
  margin-bottom: 30px;
}

.rightContent,
.leftContent {
  height: 100%;
  width: 50%;
  padding: 40px 50px;
  box-sizing: border-box;
}

.rightContent {
  text-align: right;
}

.leftContent {
  text-align: left;
}

.breakLineRight {
  margin-right: 0;
}

.breakLineLeft {
  margin-left: 0;
}

.rightContent h1,
.leftContent h1 {
  font-size: 40px;
  font-weight: 600;
}

.rightContent p,
.leftContent p {
  font-size: 22px;
  font-weight: 100;
}

.rightImage,
.leftImage {
  width: 50%;
}

.rightImage img,
.leftImage img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  box-sizing: border-box;
}

@media only screen and (max-width: 1100px) {
  .rightContainer, .leftContainer{
    height: 400px;
  }

  .leftContent, .rightContent {
    padding-top: 25px;
  }

  .rightContent h1,
  .leftContent h1 {
    font-size: 30px;
  }

  .rightContent p,
  .leftContent p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .rightContainer {
    display: flex;
    flex-direction: column-reverse;
    height: 800px;
  }

  .breakLineRight {
    margin-right: 33%;
  }

  .breakLineLeft {
    margin-left: 33%;
  }

  .leftContainer {
    display: block;
    height: 800px;
  }

  .rightContent,
  .leftContent {
    width: 100%;
    height: 55%;
    text-align: center;
    padding: 20px 25px;
  }

  .rightContent h1,
  .leftContent h1 {
    font-size: 32px;
  }

  .rightContent p,
  .leftContent p {
    font-size: 20px;
  }

  .rightImage,
  .leftImage {
    width: 100%;
    height: 45%;
  }
}
