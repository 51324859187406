.slideImage {
  width: 100%;
  height: 630px;
  margin-top: 50px;
  box-sizing: border-box;
  object-fit: fill;
}

.slideImage img {
  width: 100%;
  height: 100%;
}

.slideImage h1 {
  font-size: 100px;
  position: absolute;
  top: 43%;
  left: 32%;
}

.intoContainer {
  width: 100%;
  height: 400px;
  text-align: center;
  box-sizing: border-box;
}

.intoContainer h1 {
  font-size: 40px;
  padding: 30px 0 0 0;
  font-weight: 600;
}

.line {
  border: 1px solid #be3627;
  width: 100px;
  margin-bottom: 50px;
}

.intoContainer p {
  font-size: 20px;
  font-weight: 200;
}

.oneService {
  background-color: #e7c08d;
}

.twoService {
  background-color: white;
}

.threeService {
  background-color: #e7c08d;
}

.fourService {
  background-color: white;
}

.fiveService {
  background-color: #e7c08d;
}

.sixService {
  background-color: white;
}

@media only screen and (max-width: 1100px) {
  .intoContainer {
    padding: 60px;
  }
}

@media only screen and (max-width: 600px) {
  .slideImage {
    height: 212px;
  }
  
  .intoContainer {
    padding: 20px;
  }
}
