.slider-container {
  height: 650px;
  width: 100%;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slides {
  height: 650px;
  width: 100%;
  position: relative;
}

.slide-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: fill;
}

.slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: #f8f0e1;
  font-size: 45px;
  position: absolute;
  text-align: center;
  top: 64%;
  z-index: 10;
  font-family: '';
  font-family: 'Playfair Display', serif;
}

.slide-text {
  top: 92%;
  font-size: 25px;
  font-family: 'Helvetica';
}

.play-pause {
  color: black;
}

/* @media screen and (max-width: 600px) {
  .slide-title {
    font-size: 30px;
    top: 60%;
  }
  
  .slide-text {
    font-size: 15px;
    top: 92%;
  }

  .slide-image {
    object-fit: fill;
    overflow: hidden;
  }

  .slider-container {
    height: 350px;
    width: 100%;
    object-fit: fill;
  }

  .slides {
    height: 350px;
  }

  .dot {
    height: 1rem;
    width: 1rem;
  }
} */

/* @media screen and (min-width: 900px) and (max-width: 1100px) {
  .slider-container {
    height: 450px;
    border: 1px solid black;
  }

  .slides {
    height: 450px;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .slider-container {
    height: 450px;
  }

  .slides {
    height: 450px;
  }
}

@media screen and (max-width: 600px) {
  .slider-container {
    height: 400px;
  }

  .slides {
    height: 400px;
  }
}  */