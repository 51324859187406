.slider-container {
  height: 650px;
  width: 100%;
  position: relative;
  margin: auto;
  overflow: hidden;
  background-color: #f3f3f3;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slides {
  height: 650px;
  width: 100%;
  position: relative;
  text-align: center;
  padding: 5px 50px;
  box-sizing: border-box;
}

.slide-image {
  width: 140px;
  height: 140px;
  object-fit: fill;
  margin-top: 40px;
}

.slides h1 {
  font-size: 40px;
  font-weight: 500;
  padding-bottom: 10px;
  margin-top: 5px;
}

.slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: black;
  font-size: 20px;
  text-align: center;
  z-index: 10;
  font-weight: 400;
  font-family: "Playfair Display", serif;
  padding-top: 10px;
}

.slide-text {
  font-size: 20px;
  font-family: "Helvetica";
  font-weight: 200;
  padding-top: 50px;
}

.prev,
.next {
  color: transparent;
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 1rem;
  margin-top: -3rem;
  font-size: 40px;
  font-weight: bold;
  border-radius: 0px 5px 5px 0px;
}

.slider-container:hover .prev,
.slider-container:hover .next {
  color: black;
}

.slider-container:hover .prev:hover,
.slider-container:hover .next:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-in;
}

.next {
  right: 0;
  border-radius: 5px 0px 0px 5px;
}

.all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 85%;
  justify-content: center;
  z-index: 200;
}

.dot {
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  margin: 0px 3px;
  background-color: transparent;
  /* background-color: rgba(0, 0, 0, 0.3); */
  border-radius: 50%;
  display: inline-block;
}

.slider-container:hover .dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

/* .active-dot {
  background-color: rgba(255, 255, 255, 0.5);
} */

.slider-container:hover .dot {
  background-color: rgba(0, 0, 0, 0.3);
}
.slider-container:hover .active-dot {
  background-color: rgba(255, 255, 255, 0.5);
}

.play-pause {
  color: black;
}


@media only screen and (max-width: 600px) {
  .slider-container {
    height: 770px;
  }

  .slides {
    padding: 0 10px;
  }
}