.form-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
}

.formnamenumberemailsubject {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.captcha {
  margin-top: 40px;
}

.inputArea {
  height: 50px;
  width: 90%;
  border: none;
  border-bottom: 2px solid grey;
  font-size: 18px;
}

.inputAreaMessage {
  height: 100px;
  width: 95%;
  border: none;
  border-bottom: 2px solid grey;
  font-size: 15px;
}

.inputArea:hover,
.inputArea:active, .inputArea:focus ,.inputAreaMessage:hover,
.inputAreaMessage:active, .inputAreaMessage:focus {
  text-decoration: none;
  border-bottom: 2px solid #be3627;
  outline: none;
}

.submitButton {
  height: 50px;
  width: 98%;
  margin-top: 50px;
  cursor: pointer;
  font-size: 25px;
}

.invalid {
  color: #be3627;
}

@media only screen and (max-width: 600px) {
  .formnamenumberemailsubject {
    display: block;
  }
  
  .inputArea {
    margin-bottom: 15px;
  }
}