.container {
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: white;
  display: grid;
  grid-template-columns: 40% 60%;
  position: relative;
  z-index: 1000;
}

.logoImage img {
  height: 150px;
  width: 200px;
  margin-left: 70px;
  position: absolute;
  top: -20%;
}

.lnkContainer {
  display: flex;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  padding: 40px;
}

.linker {
  height: 100%;
  box-sizing: border-box;
  text-align: center;
}

/* .linker a:active,
.linker a.active {
  color: #de4512;
  padding-bottom: 0.25rem;
  border-bottom: 4px solid #de4512;
} */

.navLink {
  text-decoration: none;
  color: black;
  margin-left: 3rem;
  font-weight: bold;
}

.nav-btn {
  height: 50px;
  margin-left: 5px;
  margin-top: 25px;
  padding: 6px 20px 0 20px;
  cursor: pointer;
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  outline: none;
  color: #dec494;
  visibility: hidden;
  opacity: 0;
  font-size: 1.5rem;
  border-radius: 40px;
}

@media only screen and (max-width: 1200px) {
  .container {
    display: grid;
    grid-template-columns: 90% 10%;
  }

  .nav-btn {
    visibility: visible;
    opacity: 1;
    color: black;
    background-color: white;
    padding: 5px 0 2px 0;
  }

  .nav-btn:hover {
    background-color: white;
    color: black;
    cursor: pointer;
  }

  header .linker {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    color: black;
    font-size: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 3.5rem;
    background-color: white;
    transition: 1s;
    transform: translateY(-150vh);
    box-sizing: border-box;
    overflow: hidden;
    padding-right: 40px;
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: none;
  }
}

@media screen and (max-width: 768px) {
  .container {
    height: 110px;  
    grid-template-columns: 80% 20%;
  }

  .logoImage img {
    margin-left: 30px;
    margin-top: 5px;
  }

  .nav-btn {
    margin-top: 30px;
    margin-left: 50%;
  }

  header .linker {
    font-size: 40px;
    gap: 3rem;
  }
}
