.container {
  width: 100%;
  padding: 20px 40px;
  box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 40px;
}

.cardHolder {
  width: 100%;
}

.header {
  text-align: center;
}

.header h1 {
  font-size: 32px;
  font-weight: 800;
  margin-top: 120px;
}

.header hr {
  border: 1px solid #be3627;
  width: 60px;
  margin-bottom: 30px;
}

.cardHolder {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 600px) {
  .cardHolder {
    display: block;
  }
}