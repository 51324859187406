.container {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 40px;
}

.container hr {
  border: 1px solid #be3627;
  width: 30px;
  margin-bottom: 20px;
}

.container h3 {
  font-size: 20px;
  font-weight: 600;
  height: 10%;
}

.container img {
  width: 190px;
  height: 190px;
}

.container p {
  font-size: 18px;
  font-weight: 100;
}

.container:hover {
  border-radius: 30px;
  box-shadow: 8px 8px 30px 0 rgb(42 67 113 / 15%);
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 10px 20px;
  }
}