.slideImage {
  height: 600px;
  margin-top: 50px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.slideImage img {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  object-fit: fill;
}

.intro {
  text-align: center;
  padding: 50px 80px;
}

.intro h4 {
  font-size: 30px;
  margin: 40px 0 25px 0;
}

.line {
  border: 1px solid #be3627;
  width: 100px;
  margin-bottom: 60px;
}

.intro p {
  font-size: 16px;
  font-weight: 100;
  line-height: 1.5em;
}

.ourTeam {
  height: 800px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.hello {
  background-color: #fbd454;
}

.method {
  background-color: #fbd454;
  height: 50%;
  /* position: relative; */
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  transition: all 0.2s;
  will-change: transform;
  z-index: 200;
}

.methodPicture {
  height: 100%;
  background-color: #fbd454;
  position: relative;
}

.methodPicture img {
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.methodArrow {
  width: 0;
  height: 0;
  border-top: 26px solid #fbd454;
  border-left: 26px solid transparent;
  border-right: 26px solid transparent;
  position: relative;
  left: calc(43%);
  z-index: 100;
}

.method:hover {
  transform: translate3d(0, 40px, 0);
  transition: all 0.7s cubic-bezier(0.5, 0.15, 0.33, 0.88);
}

.methodContent {
  height: 50%;
  background-image: url(../../Images/About-us-element1.png);
  background-color: #ededed;
  padding: 40px;
  padding-top: 80px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 100;
  line-height: 40px;
}

.hello2 {
  background-color: #c1c1c1;
}

.family {
  height: 50%;
  position: relative;
  vertical-align: middle;
  transition: all 0.2s;
  will-change: transform;
  z-index: 200;
}

.familyPicture {
  height: 100% !important;
  background-color: #c1c1c1;
  position: relative;
  top: -6.5%;
}

.familyPicture img {
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.family:hover {
  transform: translate3d(0, -40px, 0);
  transition: all 0.7s cubic-bezier(0.5, 0.15, 0.33, 0.88);
}

.familyArrow {
  width: 0;
  height: 0;
  border-bottom: 26px solid #c1c1c1;
  border-left: 26px solid transparent;
  border-right: 26px solid transparent;
  position: relative;
  left: calc(50% - 26px);
  top: -6.5%;
  z-index: 100;
}

.familyContent {
  height: 50%;
  background-image: url(../../Images/About-us-element1.png);
  background-color: #ededed;
  padding: 40px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 100;
  line-height: 40px;
}

.hello3 {
  background-color: #9eddd5;
}

.thinker {
  height: 50%;
  position: relative;
  vertical-align: middle;
  transition: all 0.2s;
  will-change: transform;
  z-index: 200;
}

.thinkerPicture {
  height: 100%;
  background-color: #9eddd5;
}

.thinkerPicture img {
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.thinker:hover {
  transform: translate3d(0, 40px, 0);
  transition: all 0.7s cubic-bezier(0.5, 0.15, 0.33, 0.88);
}

.thinkerArrow {
  width: 0;
  height: 0;
  border-top: 26px solid #9eddd5;
  border-left: 26px solid transparent;
  border-right: 26px solid transparent;
  position: relative;
  left: calc(43%);
  z-index: 100;
}

.thinkerContent {
  height: 50%;
  background-image: url(../../Images/About-us-element1.png);
  background-color: #ededed;
  padding: 40px;
  padding-top: 80px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 100;
  line-height: 40px;
}

.ourTeam h3 {
  margin: 0;
  font-size: 22px;
}

@media only screen and (max-width: 1200px) {
  .ourTeam {
    display: block;
    height: 1200px;
  }

  .hello,
  .hello2,
  .hello3 {
    width: 100%;
    height: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    box-sizing: border-box;
    overflow: hidden;
  }

  .method,
  .methodContent {
    height: 100%;
  }

  .methodContent {
    padding-left: 80px;
  }

  .family,
  .familyContent {
    height: 100%;
  }

  .thinker,
  .thinkerContent {
    height: 100%;
  }

  .thinkerContent {
    padding-left: 80px;
  }

  .methodArrow {
    border-left: 26px solid #fbd454;
    border-bottom: 26px solid transparent;
    border-top: 26px solid transparent;
    position: relative;
    bottom: calc(63%);
    left: calc(100%);
  }

  .method:hover {
    transform: translate3d(30px, 0, 0);
    transition: all 0.7s cubic-bezier(0.5, 0.15, 0.33, 0.88);
  }

  .familyPicture {
    box-sizing: border-box;
    top: -13%;
  }

  .familyArrow {
    border-right: 26px solid #c1c1c1;
    border-bottom: 26px solid transparent;
    border-top: 26px solid transparent;
    position: relative;
    top: calc(45%);
    left: calc(-12.5%);
  }

  .family:hover {
    transform: translate3d(-30px, 0, 0);
    transition: all 0.7s cubic-bezier(0.5, 0.15, 0.33, 0.88);
  }

  .thinkerArrow {
    border-left: 26px solid #9eddd5;
    border-bottom: 26px solid transparent;
    border-top: 26px solid transparent;
    position: relative;
    bottom: calc(61%);
    left: calc(100%);
  }

  .thinker:hover {
    transform: translate3d(30px, 0, 0);
    transition: all 0.7s cubic-bezier(0.5, 0.15, 0.33, 0.88);
  }
}

@media only screen and (max-width: 768px) {
  .slideImage {
    height: 145px;
    margin-top: 0;
  }

  .ourTeam {
    height: 2100px;
  }

  .intro {
    padding: 20px;
    padding-top: 0;
  }

  .intro h4{
    padding: 0;
    margin-top: 5px;
    margin-bottom: 0;
  }

  .line{
    margin-bottom: 5px;
  }

  .intro p{
    padding-top: 5px;
  }

  .hello,
  .hello2,
  .hello3 {
    display: block;
    height: 700px;
    width: 100%;
  }

  .hello2 {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .method,
  .family,
  .thinker {
    height: 40%;
  }

  .methodContent,
  .familyContent,
  .thinkerContent {
    height: 60%;
    padding: 20px;
    padding-top: 60px;
  }

  .methodArrow {
    border-top: 26px solid #fbd454;
    border-left: 26px solid transparent;
    border-right: 26px solid transparent;
    position: relative;
    top: calc(0%);
    left: calc(43%);
  }

  .methodContent {
    border-bottom: 10px solid #fbd454;
  }

  .method:hover {
    transform: translate3d(0, 30px, 0);
    transition: all 0.7s cubic-bezier(0.5, 0.15, 0.33, 0.88);
  }

  .familyPicture {
    box-sizing: border-box;
    top: -18.9%;
  }

  .familyContent {
    border-bottom: 10px solid #c1c1c1;
    padding-top: 50px;
  }

  .familyArrow {
    border-top: 26px solid #c1c1c1;
    border-left: 26px solid transparent;
    border-right: 26px solid transparent;
    position: relative;
    top: calc(100%);
    left: calc(43%);
  }

  .family:hover {
    transform: translate3d(0, 30px, 0);
    transition: all 0.7s cubic-bezier(0.5, 0.15, 0.33, 0.88);
  }

  .thinkerArrow {
    border-top: 26px solid #9eddd5;
    border-left: 26px solid transparent;
    border-right: 26px solid transparent;
    position: relative;
    top: calc(0%);
    left: calc(43%);
  }

  .thinkerContent {
    border-bottom: 10px solid #9eddd5;
  }

  .thinker:hover {
    transform: translate3d(0, 30px, 0);
    transition: all 0.7s cubic-bezier(0.5, 0.15, 0.33, 0.88);
  }
}
